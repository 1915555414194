@import 'shared';

.Loader {
  @include position-100(fixed);
  @include vh(100);
  @include z-index(IntroLoader);
  width: 100%;
}

.inner {
  position: absolute;
  @include transform-center;
}

.texts {
  display: flex;
  flex-direction: column;
  position: relative;
}

.canadaSvgContainer {
  display: block;
  position: absolute;
  left: px(20);
  bottom: px(20);
  transform: scaleX(0) translateX(-50%);
}

.canadaSvg {
  display: block;
  width: px(15);
}

.loadedPercent {
  @include body-md;
  color: var(--bg-color);

  @include bp(mobile) {
    @include body-md;
  }
}

.name {
}

.percent {
}

.dumbText {
}

.done {
}

.underscore {
  opacity: 0;
  margin-left: px(3);
}

.imageContainer {
  width: px(100);
  height: px(100);
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}
