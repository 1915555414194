@import 'shared';

$desktop-text-width: px(280);

.AboutContent {
  @include body-md;
  color: $black;
  z-index: $z-index-inactive-page-content;
  @include position-100(absolute);

  &.active {
    @include z-index(PageContent);
  }

  @include bp(mobile) {
    display: flex;
    flex-direction: column;
    padding: px(50) var(--page-padding) var(--page-padding-vertical);
  }
}

.messageMeContainer {
  position: fixed;
  bottom: var(--page-padding);
  left: var(--page-padding);
  width: $desktop-text-width;
  @include z-index(Main);

  @include bp(mobile) {
    position: static;
    transform: none;
    width: 100%;
  }
}

.messageMeContainer__text {
  opacity: 0;

  &:not(:first-child) {
    @include bp(mobile) {
      margin-top: 10px;
    }
  }
}

.messageMeContainer__link {
  text-decoration: underline;
}

.rotatorContainer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: var(--page-padding);
  @include z-index(Main);
  opacity: 0;

  @include bp(mobile) {
    position: static;
    transform: none;
  }
}

.bioContainer {
  position: fixed;
  right: var(--page-padding);
  bottom: var(--page-padding);
  width: $desktop-text-width;
  @include z-index(Main);
  opacity: 0;

  @include bp(mobile) {
    margin-top: auto;
    position: static;
    transform: none;
    width: 100%;
    margin-bottom: px(10);
  }
}

.bioContainer__bio {
}

.background {
  z-index: $z-index-inactive-background;

  &.active {
    @include z-index(Background);
  }
}
