@import 'shared';

.rotator {
  @include reset-ul;
  display: grid;
  grid-template: 'a';
  overflow: hidden;
}

.rotatorContainer__item {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: a;
  transform: translateY(105%);

  &:first-child {
    transform: none;
  }
}

.icon {
  display: block;
  width: px(10);
  margin-left: px(3);

  [data-icon='convenience'] & {
    width: px(14);
  }

  [data-icon='simplicity'] & {
    width: px(8);
  }

  [data-icon='pain'] & {
    width: px(11);
  }

  [data-icon='options'] & {
    width: px(15);
  }
}
