@import "shared";

.Layout {
}

.scrollContainerInner {
    position: relative;
    width: 100%;
    @include z-index(Main);
}
