@import 'shared';

.loadableImageContainer {
  @include position-100(fixed);
  @include z-index(loadableImagesContainer);
  pointer-events: none;

  img,
  picture {
    position: absolute !important;
    width: px(1) !important;
    display: block;
    top: 0 !important;
    left: 0 !important;
    height: auto;
  }
}
