@import 'shared';

.BlockedContentModal {
  @include z-index(BlockedContent);
  @include position-100(fixed);
  @include flex-center;
}

.bg {
  @include position-100(absolute);
  background-color: $black;
  opacity: 0.9;
}

.inner {
  background-color: $black;
  border: 1px solid $white;
  padding: px(40) px(20);
  z-index: 2;
  position: relative;
  @include flex-center;
  flex-direction: column;
}

.inner__text {
  text-align: center;
}

.form {
}

.input {
  @include reset-button;
  border-radius: 0;
  appearance: none;
  border: none;
  background-color: transparent;
  width: px(200);
  border-bottom: 1px solid $white;
  height: px(35);
  margin-top: px(15);
  text-align: left;
  color: $white;
  text-transform: none;

  .isError & {
    border-color: red;
  }
}

.button {
  @include reset-button;
  border: 1px solid $white;
  padding: 0;
  height: px(35);
  width: px(80);
  text-align: center;
}
