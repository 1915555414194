@import 'shared';

.Cursor {
  @include z-index(Cursor);
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: px(50);
  height: px(50);
  align-items: center;
  justify-content: center;
  width: px(104);
  height: px(104);
  border-radius: 50%;
  border: 1px solid var(--fg-color);
  color: var(--fg-color);
  display: flex;

  @include bp(mobile) {
    display: none !important;
  }
}

.caretIcon {
  transform: rotate(45deg);
  width: px(16);
}

.grabText {
  @include body-md;
}
