/* prettier-ignore */

@mixin heading {
    font-weight: 600;   
    line-height: 1.2;
    font-family: $display-fonts;
    letter-spacing: -0.05em;
}

@mixin body {
  font-weight: 500;
  font-size: px(10);
  line-height: px(12);
  text-transform: uppercase;
  font-family: $body-fonts;
}

@mixin body-md {
  font-weight: 500;
  font-size: px(12);
  line-height: px(16);
  text-transform: uppercase;
  font-family: $body-fonts;

  @include bp(mobile) {
    @include body;
  }
}
