// Globals
//
// Global styling, normalize overwritten
@import 'fonts';
@import 'polyfills';
@import 'shared';

html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  text-size-adjust: none;
  font-size: 10px;
  font-family: $body-fonts;
  background-color: $black;
}

body {
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  color: $white;
  @include body;
  @include position-100(fixed);
  overflow: hidden;
  font-size: 10px;

  background-color: var(--fg-color);

  &[data-theme='dark'] {
    --fg-color: #{$white};
    --bg-color: #{$black};
  }

  &[data-theme='light'] {
    --fg-color: #{$black};
    --bg-color: #{$white};
  }

  --page-padding: #{$page-padding};
  --page-padding-vertical: #{$page-padding};

  @include bp(mobile) {
    --page-padding: #{$page-padding-mobile};
    --page-padding-vertical: #{$page-padding-vertical-mobile};
  }

  &:after {
    animation: grain 6s steps(10) infinite;
    background-image: url('../assets/images/grain.png');
    background-repeat: repeat;
    content: '';
    height: 300%;
    left: -100%;
    pointer-events: none;
    position: fixed;
    top: -100%;
    width: 300%;
    will-change: transform;
    z-index: 3;
    opacity: 0.03;
    // opacity: 0.08;
    @include z-index(Grain);
  }

  @include bp(mobile) {
    &:after {
      content: '';
      background-image: none;
      animation: none;
    }
  }

  &.disable-interactions {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }

  &[data-cursor='hover'] {
    cursor: pointer !important;

    * {
      cursor: pointer !important;
    }
  }

  &[data-cursor='grab'] {
    cursor: grab;

    * {
      cursor: grab;
    }
  }

  &[data-cursor='grabbing'] {
    cursor: -webkit-grabbing;
    cursor: grabbing;

    * {
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
  }

  // Vars
  @include bp(mobile) {
  }
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  @include vh(100);
  z-index: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

main {
  @include vh(100);
  @include z-index(pages);
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

button,
input,
a {
  &:focus:not(.focus-visible) {
    outline: none;
  }
}

// Microsoft surface
button {
  :global(.touch-device) & {
    cursor: pointer;
  }
}

.dg {
  cursor: default !important;

  * {
    cursor: auto !important;
  }
}

h1,
h2,
h3,
h4,
h5 {
  @include heading;
  text-transform: none;
}

@keyframes grain {
  0% {
    transform: translate(20%, -15%);
  }
  10% {
    transform: translate(-20%, -15%);
  }
  20% {
    transform: translate(20%, -5%);
  }
  30% {
    transform: translate(-20%, -5%);
  }
  40% {
    transform: translate(20%, 5%);
  }
  50% {
    transform: translate(-20%, 5%);
  }
  60% {
    transform: translate(20%, 15%);
  }
  70% {
    transform: translate(-20%, 15%);
  }
  80% {
    transform: translate(20%, 5%);
  }
  90% {
    transform: translate(-20%, 5%);
  }
  100% {
    transform: translate(20%, -5%);
  }
}
