@import 'shared';

.PortfolioScrollImages {
  @include reset-ul;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  position: absolute;
  pointer-events: none;
  left: 50%;
  top: 50%;
  z-index: 0;

  --main-image-width: 35vw;

  @include bp(mobile) {
    --main-image-width: 75vw;
  }
}

.item {
  width: var(--main-image-width);
  // width: 20vw;
  // border: 1px solid red;
}

.image {
  width: 100%;
  height: var(--main-image-width);
  // height: 15vh;
  object-fit: cover;
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.brand {
  display: none;
  position: absolute;
  left: 0;
  margin-top: 7vw;
}
