@import "shared";

.SanityImage {
}

.container {
}

.image {
    max-width: 100%;
    height: unset;
    object-fit: cover;
    width: 100%;
}
