@import 'shared';

.Header {
  @include body-md;
  color: var(--fg-color);

  [data-color-change] {
    transition: color 0.8s, fill 0.8s, background-color 0.8s;
  }

  @include bp(mobile) {
    display: none;
  }
}

.upper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  position: relative;
  height: px(43);
}

.name {
  line-height: 1;
  display: block;
  transform: translateY(#{px(2)});

  & > span {
    display: block;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: px(48);
  margin-right: px(-5);

  path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }
}

.left {
  @include z-index(Header);
  position: fixed;
  top: $page-padding;
  left: $page-padding;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.right {
  @include z-index(Header);
  position: fixed;
  top: $page-padding;
  right: $page-padding;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.roleText__text,
.availability__text {
  display: block;
  transform: translateY(100%);
}

.roleText,
.availability {
  position: relative;
  overflow: hidden;
  color: transparent;
}

.linkListContainer {
  position: relative;
  margin: 0;
  padding-left: px(30);
}

.followBall {
  @include box(px(8));
  background-color: var(--fg-color);
  border-radius: 50%;
  position: absolute;
  left: px(15);
  top: px(4);
  transform: scale(0);
}

.linkList__items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
}

.linkList__item {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
  position: relative;
  overflow: hidden;
  color: transparent;

  &.isActive {
    pointer-events: none;
  }
}

.linkList__itemText {
  display: block;
  transform: translateY(100%);
  // opacity: 1;

  .isActive & {
    // opacity: 0.3;
  }
}

.linkList__itemBg,
.roleText__bg,
.availabilityText__bg {
  @include position-100(absolute);
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: left center;
}

/* ====================================
MOBILE
==================================== */

// Button

.mobileButton {
  @include reset-button;
  @include circle-button;
  @include z-index(HeaderMobileButton);
  position: fixed;
  top: var(--page-padding-vertical);
  right: var(--page-padding);
  overflow: hidden;
  transition: border-color 0.2s;
  display: none;
  opacity: 0;
  border-color: var(--fg-color);
  transition: background-color 0.8s, border-color 0.8s, color 0.8s, fill 0.8s;

  [data-color-change] {
    transition: background-color 0.8s, border-color 0.8s, color 0.8s, fill 0.8s;
  }

  &.isOpen {
    border-color: var(--bg-color);
  }

  @include bp(mobile) {
    display: flex;
  }
}

.mobileButtonLine {
  background-color: var(--fg-color);
  position: absolute;
  transition: background-color 0.2s;

  .isOpen & {
    background-color: var(--bg-color);
  }

  &.line1 {
    width: px(9);
    height: 1px;
  }

  &.line2 {
    height: px(9);
    width: 1px;
  }

  &.line3 {
    width: px(15);
    height: 1px;
    transform: translateX(calc(#{$button-size} * -1.4));
  }
}

.mobileButtonBg {
  @include circle-button-bg;
}

.mobileBg {
  position: fixed;
  bottom: 0;
  left: 0;
  @include z-index(HeaderMobileBg);
  transform: scale(0);
  border-radius: 50%;
  background-color: var(--fg-color);
  display: none;

  @include bp(mobile) {
    display: block;
  }
}

.headerMobile {
  @include z-index(HeaderMobileUi);
  @include position-100(fixed);
  padding: var(--page-padding-vertical) var(--page-padding);
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  color: $black;
  pointer-events: none;
  display: none;

  [data-color-change] {
    transition: color 0.8s, fill 0.8s;
  }

  @include bp(mobile) {
    display: flex;
  }
}

.mobileLogoContainer {
  width: px(60);
  margin-top: px(9);
}

.mobileLogo {
  opacity: 0;

  .isOpen & {
    opacity: 1;
  }

  path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    color: var(--bg-color);
  }
}

.mobileLinks {
}

.mobileLinks__item {
  position: relative;
  overflow: hidden;
  opacity: 1;

  &:not(:last-child) {
    margin-bottom: px(13);
  }

  &.isActiveMobile {
    pointer-events: none;
    opacity: 0.3;
  }

  span {
    display: block;
    transform: translateY(100%);
    color: var(--bg-color);
  }
}

.mobileLinks__itemText {
  @include heading;
  font-size: px(33);
  line-height: px(33);
  text-transform: none;
}

.mobileFooter {
}

.mobileFooter__roleText,
.mobileFooter__availability,
.mobileFooter__nameText {
  position: relative;
  overflow: hidden;

  span {
    display: block;
    transform: translateY(100%);
    color: var(--bg-color);
  }
}
