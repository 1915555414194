@import 'shared';

.Background {
  @include position-100(fixed);
  grid-template: 'a';
  display: grid;
}

.wipe {
  grid-area: a;
  width: 100%;
  height: 100%;
  // transform: translateX(-100%);

  clip-path: polygon(
    0% var(--top-left-y),
    100% var(--top-right-y),
    100% var(--bottom-right-y),
    0% var(--bottom-left-y)
  );
  --top-left-y: 100%;
  --top-right-y: 100%;
  --bottom-left-y: 100%;
  --bottom-right-y: 100%;

  &.dark {
    background-color: $black;
  }

  &.light {
    background-color: $white;
  }
}
