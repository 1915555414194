@import 'shared';

.PortfolioItems {
  position: absolute;
  z-index: $z-index-inactive-page-content;

  &.active {
    @include z-index(PageContent);
  }
}

.background {
  z-index: $z-index-inactive-background;

  &.bgActive {
    @include z-index(Background);
  }
}
