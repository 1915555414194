@import 'shared';

.ScrollIndicator {
}

.innerContainer {
  width: px(23);
  height: px(76);
  border-radius: px(60);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgba($white, 0.2);
  gap: px(10);

  @include bp(mobile) {
    width: px(20);
    height: px(55);
  }
}

.up {
}

.down {
}

.upSvg,
.downSvg {
  width: px(9);
  display: block;

  @include bp(mobile) {
    width: px(7);
  }
}

.upSvg {
}

.downSvg {
  transform: rotate(180deg);
}
