@import 'shared';

$image-sizes: (
  default: 325,
  portrait: 310,
  skinny: 250,
  wide: 420,
  square: 290,
);

$large-size-multiplier: 1.3;

.AboutImages {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.largeTextContainer {
  position: relative;
  overflow: hidden;
  padding: 0 px(20);
  opacity: 0;

  .textIsSplit & {
    opacity: 1;
  }
}

.largeTextContainer__text {
  font-family: $display-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: px(322);
  line-height: px(322);
  letter-spacing: -0.07em;
  text-transform: none;
  white-space: nowrap;
  display: block;

  @include bp(desktop) {
    font-size: 26vw;
    line-height: 26vw;
  }

  :global(.split-char) {
    display: inline-block;
    transform: translateY(105%);
  }
}

.imageList {
  @include reset-ul;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.imageContainer {
  width: calc(#{px(map-get($image-sizes, default) * $large-size-multiplier)} * var(--width-variance));
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: $white;

  @include bp(laptop) {
    width: calc(#{px(map-get($image-sizes, default))} * var(--width-variance));
  }

  @include bp(mobile) {
    width: calc(50vw * var(--width-variance));
  }

  &:not(.active) {
    pointer-events: none;
  }

  &.portrait {
    width: calc(#{px(map-get($image-sizes, portrait))} * var(--width-variance));

    @include bp(mobile) {
      width: calc(40vw * var(--width-variance));
    }
  }

  &.skinny {
    width: calc(#{px(map-get($image-sizes, skinny))} * var(--width-variance));

    @include bp(mobile) {
      width: calc(37vw * var(--width-variance));
    }
  }

  &.wide {
    width: calc(#{px(map-get($image-sizes, wide) * $large-size-multiplier)} * var(--width-variance));

    @include bp(laptop) {
      width: calc(#{px(map-get($image-sizes, wide))} * var(--width-variance));
    }

    @include bp(mobile) {
      width: calc(60vw * var(--width-variance));
    }
  }

  &.square {
    width: calc(#{px(map-get($image-sizes, square) * $large-size-multiplier)} * var(--width-variance));

    @include bp(laptop) {
      width: calc(#{px(map-get($image-sizes, square))} * var(--width-variance));
    }

    @include bp(mobile) {
      width: calc(50vw * var(--width-variance));
    }
  }

  &:active {
    &:before {
      @include unselectable;
      content: '';
      background-color: green;
      @include position-100(absolute);
      transform: scale(6);
      opacity: 0;
    }
  }

  &:after {
    @include unselectable;
    content: '';
    display: block;
    @include position-100(absolute);
    touch-action: pan-x;
    background-color: red;
    opacity: 0;
  }
}

.image {
  @include unselectable;
  display: block;
  filter: blur(0px); // fixes jaggy rotation edges
  // opacity: 0.3;
  // transition: opacity 0.3s;

  .active & {
    // opacity: 1;
  }
}

.altContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  @include unselectable;
}

.altContainer__bg {
  @include position-100(absolute);
  background-color: $black;
  z-index: 1;
  transform: scaleX(0);
  transform-origin: left center;
}

.altContainer__text {
  display: block;
  @include body-md;
  color: $white;
  position: relative;
  z-index: 2;
  transform: translateY(105%);
}
