@import 'layout.scss';

// Variables
//
// Add global variables here, color palette, those variables shared by components all applied to global styling

// ====================================================================
// Breakpoints
//
// Window breakpoints
// ====================================================================
$layout-mobile: map-get($layout, mobile) + px;
$layout-tablet: map-get($layout, tablet) + px;
$layout-laptop: map-get($layout, laptop) + px;
$layout-desktop: map-get($layout, desktop) + px;
$layout-xl: map-get($layout, xl) + px;

// ====================================================================
// Base palette
//
// Grayscale and brand colors for use across the site.
// ====================================================================
$white: #f9f7f7;
$black: #141414;
$black-dark: #080808;

// ====================================================================
// General
//
// Global color variables, used usually in sections
// Global variables used across the web app
// ====================================================================
$page-padding: px(43);
$page-padding-mobile: px(20);
$page-padding-vertical-mobile: px(30);

// ====================================================================
// Header
// ====================================================================
$button-size: px(46);

// ====================================================================
// Portfolio Items
// ====================================================================
// $single-gallery-gap: 0px;
$single-gallery-gap: 25px;

// ====================================================================
// Body Fonts
// ====================================================================
$faktum-font: 'Faktum';
$monument-font: 'Monument Grotesk';
$display-fonts: $faktum-font, Helvetica, Arial, sans-serif;
$body-fonts: $monument-font, Helvetica, Arial, sans-serif;

// ====================================================================
// List of z-index element
// ====================================================================

$z-index-inactive-background: 20;
$z-index-inactive-page-content: 22;
$z-index-inactive-webgl: 21;

$elements: Background, WebGL, PortfolioOverlay, PageContent, TeammateComments, Main, IntroLoader, Header, HeaderMobileBg,
  HeaderMobileUi, HeaderMobileButton, BlockedContent, Grain, Cursor;
