@import 'shared';

.PortfolioScrollItem {
  @include z-index(Main);
  position: fixed;
  top: 50%;
  left: var(--page-padding);
  transform: translateY(-50%);

  --brand-height: #{px(60)};

  @include bp(mobile) {
    --brand-height: #{px(45)};

    opacity: 0 !important;
    pointer-events: none;

    // transform: translateY(0);
    // top: auto;
    // bottom: calc(calc(var(--count) * var(--brand-height) * 0.5 - var(--brand-height) * 2) * -1);
  }

  &.isEven {
    margin-top: calc(var(--brand-height) * -1 * 0.25);

    @include bp(mobile) {
      margin-top: 0;
    }
  }
}

.mobileOverlay {
  @include position-100(fixed);
  @include z-index(PortfolioOverlay);
  pointer-events: none;
  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 52.6%, #141414 90.1%);
  display: none;
  opacity: 0;

  @include bp(mobile) {
    display: block;
  }
}

.brandContainer {
  position: relative;
  z-index: 2;
}

.brandContainerInner {
  opacity: 0;
  position: relative;
  width: 10px;
  height: calc(var(--count) * var(--brand-height));
}

.PortfolioScrollItem__brandTitleContainer {
  height: var(--brand-height);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
}

.lowerInfo__brand {
  font-weight: 600;
  font-size: px(44);
  line-height: px(44);
  line-height: 1;
  letter-spacing: -0.05em;
  width: 100%;

  @include bp(mobile) {
    font-weight: 600;
    font-size: px(33);
    line-height: px(33);
    letter-spacing: -0.04em;
  }

  .splitComplete & {
    opacity: 1;
  }

  .longTitle & {
    font-size: px(101);
    line-height: px(92);
    max-width: 50%;
    margin-left: 0;
  }

  :global(.split-word) {
    position: relative;
  }

  :global(.split-char) {
    display: block;
    // opacity: 0;
  }
}

.scrollIndicator {
  @include z-index(Main);
  opacity: 0;
  position: fixed;
  top: 50%;
  right: var(--page-padding);
  transform: translateY(-50%);

  @include bp(mobile) {
    top: auto;
    bottom: var(--page-padding-vertical);
    transform: translateY(0);
  }
}

/* ============================
Mobile titles
============================ */

.mobileTitles {
  @include z-index(Main);
  position: fixed;
  bottom: var(--page-padding-vertical);
  left: var(--page-padding);
  grid-template: 'a';
  display: none;
  overflow: hidden;
  opacity: 0;

  @include bp(mobile) {
    display: grid;
  }
}

.mobileTitle {
  grid-area: a;
  pointer-events: none;
}

.mobileTitle__title {
  @include heading;
  font-size: px(33);
  line-height: px(33);
  // transform: translateY(-150%) skewY(-10deg);
  transform: translateY(-150%);
  white-space: nowrap;
}

.mobileTitle__brand {
  @include body;
  // transform: translateY(150%) skewY(10deg);
  transform: translateY(150%);
  opacity: 0.5;
}
