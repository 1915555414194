@import 'shared';

.WebGL {
  @include position-100(fixed);
  z-index: $z-index-inactive-webgl;

  &.active {
    @include z-index(WebGL);
  }
}
