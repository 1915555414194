@import 'shared';

.PortfolioSingleContent {
  @include body-md;
}

.top {
  @include z-index(Header);
  position: fixed;
  top: $page-padding;
  left: 0;
  padding: 0 $page-padding;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  opacity: 0;
}

.topLeft {
  opacity: 0.5;
}

.role {
}

.agency {
}

.topCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.back {
  @include reset-button;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.backCaretCircle {
  @include circle-button;
  margin-right: px(15);

  @include bp(mobile) {
    border: 1px solid $white;
  }
}

.backCaretCircleBg {
  @include circle-button-bg;
}

.backCaret {
  width: px(12);
  transform: rotate(-90deg);
  position: relative;
  z-index: 2;
  display: block;
}

.backText {
}

.topRight {
}

.bottom {
  position: fixed;
  bottom: $page-padding;
  left: 0;
  padding: 0 $page-padding;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  @include z-index(Header);
}

.bottomLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scrollIndicator {
  margin-right: px(15);
  opacity: 0;
}

.brand {
  opacity: 0;
}

.projectTitle {
  font-weight: 600;
  font-size: px(70);
  line-height: 1;
  letter-spacing: -0.05em;
  opacity: 0;

  span span {
    display: inline-block;
    opacity: 0;
  }
}

.teammateCommentsButton {
  @include reset-button;
}

.bottomRight {
  opacity: 0;
  text-align: right;
}

.projectTitle {
}

.link {
}

.awards {
  white-space: break-spaces;
}

/* ===========================
Mobile
=========================== */

.mobileContent {
  @include position-100(fixed);
  padding: var(--page-padding-vertical) var(--page-padding);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  @include z-index(Header);
  opacity: 0;
  width: 100%;
}

.mobileBack {
  @include reset-button;
}

.mobileTextContent {
  width: 100%;
}

.mobileContent__title {
  @include heading;
  font-size: px(33);
  line-height: px(33);
  margin-bottom: px(10);
}

.mobileContent__brand,
.mobileContent__role,
.mobileContent__agency,
.mobileContent__awards,
.mobileContent__link {
  @include body;
  opacity: 0.5;
}

.mobileContent__brand,
.mobileContent__role,
.mobileContent__agency,
.mobileContent__awards {
  user-select: none;
}

.mobileContent__teammateComments {
  @include reset-button;
  @include body;
  display: block;
  margin-top: px(10);
  user-select: none;
}

.mobileContent__awards {
  white-space: break-spaces;
}

.mobileContent__footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.mobileContent__link {
  opacity: 1;
}

/* ===========================
Mobile
=========================== */

.teammateCommentsModal {
  @include position-100(fixed);
  @include z-index(TeammateComments);
  @include flex-center;
  opacity: 0;
  pointer-events: none;
}

.teammateCommentsModal__bg {
  @include position-100(absolute);
  background-color: $black;
}

.teammateCommentsModal__text {
  position: relative;
  font-family: $display-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: px(28);
  line-height: px(35);
  text-align: justify;
  letter-spacing: -0.05em;
  color: $white;
  text-transform: none;
  max-width: px(700);

  @include bp(mobile) {
    font-size: px(22);
    line-height: px(28);
    max-width: 100%;
    width: 100%;
    padding: var(--page-padding);
  }
}
