@import 'shared';

$desktopHeight: 47vh;
// $desktopHeight: 7vh;

.PortfolioScrollImagesSingle {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(var(--offset-x), -50%);
  pointer-events: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $single-gallery-gap;
  z-index: 12395823965589;

  --image-height: 47vh;

  @include bp(mobile) {
    --image-height: 29vh;
  }

  &.isEven {
    // margin-left: $desktopWidth / 2;
  }
}

.isMain {
  display: block;
}

.item {
  width: calc(var(--image-height) * var(--aspect-ratio));
}

.image {
  height: var(--image-height);
  width: 100%;
  opacity: 0;
  // outline: 1px solid red;

  .PortfolioScrollImagesSingle:nth-child(5) & {
    // opacity: 1;
    // outline: 1px solid red;
  }
}
