// Sass Mixins
//
// More information about mixins in:
// http://sass-lang.com/documentation/file.SASS_REFERENCE.html#mixins
// Add comment to every function
@import 'vars';

// Define font-size, line-height and letter-spacing in function
// @example scss Input
//   p {
//     // font-size: 16px;
//     // letter-spacing: -0.1px;
//     // line-height: 1.5; // This is line-height 24px and font-size: 16px
//     @include font-size(16, 24, -0.1)
//   }
@mixin font-size(
  $font-size,
  $line-height: normal,
  $letter-spacing: normal,
  $font-weight: 400,
  $font-family: false,
  $text-transform: false
) {
  font-size: px($font-size);
  line-height: $line-height;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;

  @if $font-family {
    font-family: $font-family;
  }

  @if $text-transform {
    text-transform: $text-transform;
  }
}

/// Improve animation performance for containers
/// @example scss Input
///   .card {
///     @include gpu();
///   }
@mixin gpu {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;

  .safari & {
    perspective: none; // otherwise text and icons become blurry
  }
}

/* ============================================
Height
============================================ */

@mixin vh($vh) {
  height: #{$vh}vh;
  height: calc(var(--vh, 1vh) * #{$vh});
}

/// Set exactly the same width and height of a component, avoid repeat the same dimension
/// @parameter {Measure unit} $width - Specify width of the box
/// @parameter {Measure unit} $height [$width] - Specify height of the box, the default value is the width
/// @example scss Input
///   .card {
///     @include box(3.2rem);
///   }
///   .card-another {
///     @include box(3.2rem, 2rem);
///   }
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

/// Convert the pixels to rem for in a specific resolution, usually 1440px wide
/// @example scss Input
///   section {
///     height: px(200);
///   }
///
@function px($px) {
  @return $px * 0.1rem;
}

/// Set top and left to 0
/// @example scss Input
///   element {
///     @include position-0;
///   }
@mixin position-0 {
  top: 0;
  left: 0;
}

/// Set full size of box and position
/// @parameter {string} $pos - one of relative, absolute, fixed. relative by default
/// @example scss Input
///   element {
///     @include position-100(absolute);
///   }
@mixin position-100($pos: relative) {
  position: $pos;
  @include position-0;
  @include box(100%);
}

/// Set display flex and center children horizontally and vertically
/// @example scss Input
///   element {
///     @include flex-center;
///   }
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/// Set display flex and center children vertically
/// @example scss Input
///   element {
///     @include flex-center-vert;
///   }
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

/// Set display flex and center children horizontally
/// @example scss Input
///   element {
///     @include flex-center-horiz;
///   }
@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

/// Center its element (horizontally and vertically) by using transform
/// @example scss Input
///   element {
///     @include transform-center;
///   }
@mixin transform-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/// Set vertical center of its element
/// @example scss Input
///   element {
///     @include transform-center-vert;
///   }
@mixin transform-center-vert {
  top: 50%;
  transform: translateY(-50%);
}

/// Set horizontal center of its element
/// @example scss Input
///   element {
///     @include transform-center-horiz;
///   }
@mixin transform-center-horiz {
  left: 50%;
  transform: translateX(-50%);
}

/// Set z-index value in order of $element variable
/// @example scss Input
///   element {
///     @include z-index(preloader);
///   }
@mixin z-index($id) {
  z-index: index($elements, $id) + 100;
}

/// Set breakpoint via media queries
/// @example scss Input
///   element {
///     [...phone styles....]
///     @include bp(tablet) {
///        [...tablet+desktop styles...]
///     }
///   }
@mixin bp($size, $is-min-width: false) {
  @if ($is-min-width) {
    @media (min-width: map-get($layout, $size) + px) {
      @content;
    }
  } @else {
    @media (max-width: map-get($layout, $size) + px) {
      @content;
    }
  }
}

// Hover mixin to ensure
// this doesn't show up on mobile

@mixin hover {
  @media (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@mixin reset-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

/// Reset button's look
/// @example scss Input
///   button {
///     @include reset-button;
///   }
@mixin reset-button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: currentColor;
}

/* ============================================
Make things unselectable/unhighlightable
============================================ */

@mixin unselectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* ============================================
For page containers that don't have scrolling
Also fixes scrolling behaviour on tablet/mobile
============================================ */

@mixin full-dimension-container {
  $max-viewport-height: calc(var(--vh, 1vh) * 100);

  @include vh(100);
  max-height: $max-viewport-height;
  width: 100%;
  max-width: 100vw;
  position: fixed;
  overflow: hidden;
}

/* ============================================
Container
============================================ */

@mixin container {
  width: 100%;
  max-width: px(map-get($layout, laptop));
  margin: 0 auto;
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
}

/* ============================================
Circle Button
============================================ */

@mixin circle-button {
  width: $button-size;
  height: $button-size;
  border-radius: 50%;
  border: 1px solid rgba($white, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@mixin circle-button-bg {
  @include position-100(absolute);
  background-color: $white;
  border-radius: 50%;
  transform: scale(0);
}
